.text-primary {
  color: #440099;
}
.card-container > .ant-tabs-card .ant-tabs-content {
  margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
  padding: 30px;
  background: #fff;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
  display: none;
}
.ant-checkbox:not(.ant-checkbox-checked) > .ant-checkbox-inner {
  border-color: #6b7280;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #06CE80 !important;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #06CE80 !important;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #06CE80 !important;
}

@primary-color: #440099;@menu-dark-bg: transparent;@menu-dark-item-active-bg: #5B21B6;@menu-dark-inline-submenu-bg: transparent;@table-header-color: #6b7280;@table-header-bg: #f3f4f6;@tabs-card-head-background: #E9E9E9;